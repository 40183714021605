<template>
  <div
      class="f6 h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done"
      style="height: 100vh; overflow: hidden">
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <contract-setting-left-menu></contract-setting-left-menu>
        <div class="h-100 flex-auto text-align-left">
          <div class="flex flex-column h-100 w-100 pl1 pr4">
            <MetaRightSideHeader>
              <template #title>订单</template>
              <template #bottom-tip>
                您的方案将于20xx年xx月xx日到期
              </template>
            </MetaRightSideHeader>
            <div class="data-container shadow" style="flex: 1">
              <div style="padding-left: 28px;padding-right: 28px">
                <div class="fw5" style="margin-top: 30px">
                  <div style="display: flex;justify-content: space-between">
                    <h2 class="gray ttu mb3 mt0">
                      合同使用记录
                    </h2>
                    <h3 class="gray ttu mb3 mt0">
                      {{"总消耗份数: "+countedSum}}
                    </h3>
                  </div>
                  <MetaListTable
                      :table-data="orderList"
                      :table-height="tableHeight"
                      :show-table-header="false"
                  >
                    <el-table-column
                        label="合同标识"
                        fixed="right"
                        show-overflow-tooltip
                        prop="contractName"
                    >
                    </el-table-column>
                    <el-table-column
                        label="版本编号"
                        fixed="right"
                        prop="versionCode"
                    >
                    </el-table-column>
                    <el-table-column
                        label="消耗份数"
                        fixed="right"
                        prop="counted"
                    >
                    </el-table-column>
                    <el-table-column
                        label="使用时间"
                        fixed="right"
                        prop="createTime"
                    >
                    </el-table-column>
                    <el-table-column
                        label="使用人"
                        fixed="right"
                        prop="userName"
                    >
                    </el-table-column>
                  </MetaListTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import ContractSettingLeftMenu from "../components/ContractSettingLeftMenu";
import {getTeamReviewRecord} from "../api/api";
import MetaListTable from "../components/list/MetaListTable";
import MetaRightSideHeader from "../components/universalUI/MetaRightSideHeader";

export default {
  name: "Order",
  components: {MetaRightSideHeader,ContractSettingLeftMenu,MetaListTable},
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      orderList: [],
      countedSum: 0,
      tableHeight: document.body.clientHeight - 150,
    }
  },
  mounted() {
    getTeamReviewRecord(this.userInfo.mainSiteTeamId).then(res => {
      if (res.data.code == 0) {
          this.orderList = res.data.data;
          this.orderList.forEach(value => {
            this.countedSum += value.counted;
          });
      }else {
        this.metaMessage['error']('该团队订单使用情况获取失败');
      }
    }).catch(err => {
      console.log(err)
      this.metaMessage['error']("该团队订单使用情况获取失败");
    })
    window.onresize=()=>{
      this.tableHeight=document.body.clientHeight - 150
    }
  },
  methods: {
  }
}

</script>

<style scoped>
/*@import url("../assets/css/contract.css");*/
@import url("../assets/css/concord.css");
.data-container {
  text-align: left;

  background: white;
}

.data-dialog {
  text-align: left;

}

.el-dialog__header {
  text-align: left;
}

.addData-btn{
  height: 32px;
  box-sizing: border-box;
  min-height: unset;
  line-height: 6px;
  margin-left: 5px;
}

.stage-index{
  padding-right: 5px;
  border-right: solid 1px #ddd;
  margin-right: 10px;
  height: 20px;
  line-height: 20px;

}
</style>
